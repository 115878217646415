<template>
  <b-card>
    <validation-observer ref="vo">
      <b-form @submit.prevent="onSubmitForm">
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('Current Password')"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="current_password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueCurrent"
                    :type="passwordFieldTypeCurrent"
                    :state="errors.length ? false : null"
                    :placeholder="$t('Current Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredPasswordCurrent') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('New Password')"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="new_password"
                vid="New Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="passwordValueNew"
                    :type="passwordFieldTypeNew"
                    :state="errors.length ? false : null"
                    name="new-password"
                    :placeholder="$t('New Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredPasswordNew') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('Retype New Password')"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="retype_new_password"
                rules="required|confirmed:New Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="passwordValueNewRetype"
                    :type="passwordFieldTypeNewRetype"
                    :state="errors.length ? false : null"
                    name="retype-password"
                    :placeholder="$t('New Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredPasswordNewRetype') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mt-1">
          <b-button
            variant="primary"
            type="submit"
            :disabled="isLoading"
          >
            {{ $t('Save') }}
          </b-button>
          <!--<b-button
            type="reset"
            variant="outline-secondary"
            class="ml-1"
            :disabled="isLoading"
            @click.prevent="resetForm"
          >
            {{ $t('Cancel') }}
          </b-button>-->
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import CHANGE_USER_PASSWORD from '@/gql/mutation/user/changeUserPassword.gql'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      passwordValueCurrent: '',
      passwordValueNew: '',
      passwordValueNewRetype: '',
      passwordFieldTypeCurrent: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeNewRetype: 'password',
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeCurrent === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeNewRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeCurrent = this.passwordFieldTypeCurrent === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeNewRetype = this.passwordFieldTypeNewRetype === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.passwordValueCurrent = ''
      this.passwordValueNew = ''
      this.passwordValueNewRetype = ''
      this.$refs.vo.reset()
    },
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (!isValid) {
          return
        }

        try {
          this.isLoading = true

          await this.$apollo.mutate({
            mutation: CHANGE_USER_PASSWORD,
            variables: {
              oldPassword: this.passwordValueCurrent,
              newPassword: this.passwordValueNew,
            },
          })

          this.resetForm()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success'),
              text: this.$t('notificationSuccessPasswordChanged'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>
