<template>
  <b-card>
    <validation-observer ref="vo">
      <b-form @submit.prevent="onSubmitForm">
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('First Name')"
              label-for="first-name"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="first_name"
                rules="required"
              >
                <b-form-input
                  v-model="firstName"
                  name="fist-name"
                  :placeholder="$t('placeholderFirstName')"
                  :state="errors.length ? false : null"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredFirstName') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('Last Name')"
              label-for="lasts-name"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="last_name"
                rules="required"
              >
                <b-form-input
                  v-model="lastName"
                  name="last-name"
                  :placeholder="$t('placeholderLastName')"
                  :state="errors.length ? false : null"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredLastName') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('Email Address')"
              label-for="account-email"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="emailCurrent"
                  name="email"
                  :state="errors.length ? false : null"
                  placeholder="john@example.com"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredEmail') }}</template>
                  <template v-else-if="failedRules.email">{{ $t('validationErrorEmail') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="$t('Phone Number')"
              label-for="account-phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone_number"
                rules=""
              >
                <b-form-input
                  v-model="phone"
                  :state="errors.length ? false : null"
                  placeholder="+16462806850"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mt-1">
          <b-button
            variant="primary"
            type="submit"
            :disabled="isIdenticalData || isLoading"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import UPDATE_USER_DATA from '@/gql/mutation/user/updateUserData.gql'
import CHANGE_USER_EMAIL from '@/gql/mutation/user/changeUserEmail.gql'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      emailCurrent: '',
      phone: '',
      firstName: '',
      lastName: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    isIdenticalData() {
      return this.emailCurrent === this.user.email
        && this.phone === this.user.phone
        && this.firstName === this.user.firstName
        && this.lastName === this.user.lastName
    },
  },
  created() {
    this.emailCurrent = this.user.email
    this.phone = this.user.phone
    this.firstName = this.user.firstName
    this.lastName = this.user.lastName
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUser',
    }),
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (!isValid) {
          return
        }

        try {
          this.isLoading = true

          if (this.user.email !== this.emailCurrent) {
            await this.$apollo.mutate({
              mutation: CHANGE_USER_EMAIL,
              variables: {
                email: this.emailCurrent,
              },
            })
            this.emailCurrent = this.user.email
          }

          const phone = this.phone ? this.phone : null
          await this.$apollo.mutate({
            mutation: UPDATE_USER_DATA,
            variables: {
              firstName: this.firstName,
              lastName: this.lastName,
              phone,
            },
          })
          this.setUser({
            ...this.user,
            firstName: this.firstName,
            lastName: this.lastName,
            phone,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success'),
              text: this.$t('notificationSuccessSaved'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>
