<template>
  <div>
    <h3 class="mt-1 mb-2">
      {{ $t('User Account') }}
    </h3>

    <b-tabs
      pills
      vertical
      nav-class="nav-left"
      content-class="col-9"
      nav-wrapper-class="col-3"
    >
      <b-tab active>
        <template #title>
          <!--<feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />-->
          <span class="font-weight-bold">{{ $t('userAccountInfoTabName') }}</span>
        </template>
        <account-setting-general />
      </b-tab>
      <b-tab>
        <template #title>
          <!--<feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />-->
          <span class="font-weight-bold">{{ $t('userAccountPasswordTabName') }}</span>
        </template>
        <account-setting-password />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
}
</script>
