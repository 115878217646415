var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"vo"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('First Name'),"label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"name":"fist-name","placeholder":_vm.$t('placeholderFirstName'),"state":errors.length ? false : null},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredFirstName')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Last Name'),"label-for":"lasts-name"}},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"name":"last-name","placeholder":_vm.$t('placeholderLastName'),"state":errors.length ? false : null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredLastName')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email Address'),"label-for":"account-email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"name":"email","state":errors.length ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.emailCurrent),callback:function ($$v) {_vm.emailCurrent=$$v},expression:"emailCurrent"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredEmail')))]:(failedRules.email)?[_vm._v(_vm._s(_vm.$t('validationErrorEmail')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Phone Number'),"label-for":"account-phone"}},[_c('validation-provider',{attrs:{"name":"phone_number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"+16462806850"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"mt-1"},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isIdenticalData || _vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }